var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            {
              staticClass: "mx-auto",
              attrs: {
                align: "center",
                justify: "center",
                cols: "12",
                sm: "8",
              },
            },
            [
              _c(
                "v-col",
                { attrs: { align: "start", justify: "start" } },
                [
                  _c(
                    "v-btn",
                    { staticClass: "mb-3", attrs: { color: "primary" } },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "white--text text-h5 mb-4",
                      attrs: { color: "secondary" },
                    },
                    [_vm._v(" Select team members ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "pa-4" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          outlined: "",
                          dense: "",
                          filled: "",
                          "small-chips": "",
                          "deletable-chips": "",
                          items: _vm.salespersonOptions,
                          label: "Salesperson name",
                          multiple: "",
                        },
                        model: {
                          value: _vm.salespersons,
                          callback: function ($$v) {
                            _vm.salespersons = $$v
                          },
                          expression: "salespersons",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", plain: "" },
                              on: { click: _vm.clearSalesperson },
                            },
                            [_vm._v(" Clear ")]
                          ),
                          _c("v-btn", { attrs: { color: "primary" } }, [
                            _vm._v(" Confirm "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }